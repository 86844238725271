<template>
  <div>
    <div v-if="detailInfo" class="row m-0">
      <div class="col-md-6">
        <label for="propCode">제어 모드</label>
        <!-- <input v-model="detailInfo.ctrlMode" type="text" class="form-control" id="propCode"
                    :disabled="!isEditMode"> -->
        <valid-input
          :vid="'제어 모드'"
          :classList="'form-control'"
          :inputType="'text'"
          :inputValue.sync="detailInfo.ctrlMode"
          :placeholder="$t('제어 모드')"
          :rules="rules.COMMON_CODE_PUBLIC_RULE"
          :errorMessage="validMessage.COMMON_CODE_PUBLIC_VALID_MESSAGE"
          :disabled="!isEditMode"
        ></valid-input>
      </div>
      <div class="col-md-6">
        <label for="propName">제어 모드명</label>
        <!-- <input v-model="detailInfo.ctrlModeName" type="text" class="form-control" id="propName"
                    :disabled="!isEditMode"> -->
        <valid-input
          :vid="'제어 모드명'"
          :classList="'form-control'"
          :inputType="'text'"
          :inputValue.sync="detailInfo.ctrlModeName"
          :placeholder="$t('제어 모드명')"
          :rules="rules.COMMON_NAME_RULE"
          :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
          :disabled="!isEditMode"
        ></valid-input>
      </div>
      <div class="col-md-12" style="margin-top: 1rem">
        <p
          v-if="isEmpty(tempCtrlModeIndex) && tempCtrlModeIndex !== 0"
          class="mb-0"
        >
          제어모드에 포함시킬 제어 항목을 선택하세요.
        </p>
        <TableListComponent
          ref="TableListComponent"
          :mode="tableMode"
          :title="''"
          :columns="columns"
          :rows="detailInfo.outProps"
          :keyField="'propCode'"
          :transCodes="transCodes"
          :useFilter="false"
          :useMaxHeight="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
  components: {
    TableListComponent,
  },
  props: ["mode", "detailData", "tempCtrlModeIndex"],
  data() {
    return {
      transCodes: [
        {
          field: "unitType",
          codes: this.$store.state.unitTypes,
        },
        {
          field: "unit",
          codes: this.$store.state.units,
        },
      ],
      columns: [
        {
          label: this.$t("제어 항목"),
          field: "rowTitle",
          type: "text",
          compositionFields: ["propName", "propCode"],
        },
        {
          label: this.$t("관제 유형"),
          field: "ioType",
          type: "text",
        },
        {
          label: this.$t("관제값 유형"),
          field: "unitType",
          type: "text",
        },
        {
          label: this.$t("단위"),
          field: "unit",
          type: "text",
        },
      ],
      detailInfo: null,
      rules,
      validMessage,
    };
  },
  watch: {},
  computed: {
    isEditMode() {
      return this.mode !== "view";
    },
    tableMode() {
      return this.isEmpty(this.tempCtrlModeIndex) &&
        this.tempCtrlModeIndex !== 0
        ? "edit"
        : "view";
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    initData() {
      let outProps = this.detailData.ioProps.filter((prop) =>
        ["Out", "Both"].includes(prop.ioType)
      );
      return {
        ctrlMode: "",
        ctrlModeName: "",
        enabled: "Y",
        outProps: outProps,
      };
    },
    setData(data) {
      if (data) {
        this.detailInfo = data;
        this.detailInfo.outProps = this.detailInfo.outProps.map((prop) =>
          this.detailData.ioProps.find(
            (dataProp) => dataProp.propCode === prop.propCode
          )
        );
      } else {
        this.detailInfo = this.initData();
      }
    },
    getData() {
      this.detailInfo.outProps =
        this.$refs["TableListComponent"].getCheckedRows();
      return this.detailInfo;
    },
  },
};
</script>