<template>
  <div class="ld-container" style="height: 90vh; width: 100%">
    <div class="row">
      <HeaderBox :title="`${$t('시스템 관리')} > ${$t('설비유형코드 관리')}`" />
    </div>
    <div class="row" style="height: calc(100% - 40px)">
      <div class="list-area col-md-6">
        <TableList
          ref="table-list"
          :title="'설비유형코드'"
          :columns="columns"
          :rows="equipTypeList"
          :keyField="'equipType'"
          :groupField="'equipCategory'"
          :toggleFields="['enabled']"
          :transCodes="transCodes"
          :controls="controls"
          :mode="'edit'"
          :useMaxHeight="true"
          @button-click="handleListButtonClick"
          @row-click="handleItemClick"
          @toggle-changed="handleItemToggle"
        />
      </div>
      <div class="detail-area col-md-6">
        <EquipTypeDetail
          ref="EquipTypeDetail"
          :title="'설비유형코드'"
          :equipType="equipType"
          @data-updated="handleDetailUpdated"
          @closed="handleDetailClosed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

import EquipTypeDetail from "./EquipTypeDetail.vue";
import backEndApi from "@src/api/backEndApi";
import moment from "moment";

export default {
  components: {
    HeaderBox,
    TableList,
    EquipTypeDetail,
  },
  props: {},
  data() {
    return {
      equipType: null,
      controls: [
        { event: "delete", label: "삭제", class: "btn-danger" },
        { event: "refresh", label: "갱신", class: "bg-white text-dark" },
        {
          event: "status",
          label: "사용",
          class: "bg-white text-dark",
          options: { enabled: "Y" },
        },
        {
          event: "status",
          label: "미사용",
          class: "bg-white text-dark",
          options: { enabled: "N" },
        },
        { event: "regist", label: "추가", class: "btn-primary" },
      ],
      transCodes: [
        {
          field: "equipCategory",
          codes: this.$store.state.commonCodes.equipCategory,
        },
        {
          field: "enabled",
          codes: this.$store.state.commonCodes.enabled,
        },
      ],
      columns: [
        // 테이블 column
        {
          label: this.$t("설비유형코드"),
          field: "rowTitle",
          type: "text",
          compositionFields: ["equipType", "equipTypeName"],
          // isFilter: true,
        },
        {
          label: this.$t("설비 분류"),
          field: "equipCategory",
          type: "text",
          // isFilter: true,
        },
        {
          label: this.$t("연결 드라이버"),
          field: "driverType",
          type: "text",
          // isFilter: true,
        },
        {
          label: this.$t("계측 속성"),
          field: "ioPropCnt",
          type: "text",
          // isFilter: false,
        },
        {
          label: this.$t("계산 속성"),
          field: "calcPropCnt",
          type: "text",
          // isFilter: false,
        },
        {
          label: this.$t("사용자 정의 속성"),
          field: "userPropCnt",
          type: "text",
          // isFilter: false,
        },
        {
          label: this.$t("제어 모드"),
          field: "ctrlModeCnt",
          type: "text",
          // isFilter: false,
        },
        {
          label: this.$t("사용여부"),
          field: "enabled",
          width: "100px",
          type: "text",
          // isFilter: true,
        },
      ],

      equipTypeList: null,
    };
  },
  computed: {},
  async created() {
    await this.getDataList();
  },
  async mounted() {
    // await this.getDataList();
  },
  methods: {
    async handleListButtonClick(event, options) {
      switch (event) {
        case "regist":
          await this.registNew();
          break;

        case "delete":
          await this.deleteChecked();
          break;

        case "refresh":
          await this.getDataList(event);
          break;

        case "status":
          await this.changeStatusChecked(options);
          break;

        default:
          console.warn(
            "Unhandled Component Event. event:",
            event,
            "options:",
            options
          );
          break;
      }
    },

    async handleItemToggle(key, field, status) {
      await this.changeStatus(key, field, status);
    },

    async handleItemClick(row, idx, toggle, check) {
      if (toggle || check) return;

      const confirm = await this.$refs["EquipTypeDetail"].notifyCancelEdit();
      if (confirm) {
        this.equipType = row.equipType;
        // this.$nextTick(() => {
        //     this.$refs['EquipTypeDetail'].loadData();
        // })
      }
    },
    /**
     * Table List Management Functions
     */
    async getDataList(event) {
      try {
        let result = await backEndApi.equipTypes.getEquipTypeList();
        if (result && result.data) {
          this.equipTypeList = result.data.map((row) => {
            row.regDate = row.redDate
              ? moment(row.regDate).format("YYYY-MM-DD")
              : null;
            row.modDate = row.modDate
              ? moment(row.modDate).format("YYYY-MM-DD")
              : null;
            return row;
          });
          if (event) await this.alertNoti("갱신되었습니다.");
        } else {
          console.error("equipTypes.getDataList API Failed");
        }
        return result;
      } catch (e) {
        console.error(e);
      }
    },

    async deleteChecked() {
      const that = this;
      const checked = this.$refs["table-list"].getCheckedRows();
      if (that.isEmpty(checked)) {
        this.alertNoti("삭제할 목록을 선택해주세요.");
        return;
      }
      try {
        const confirm = await this.alertConfirmWarning(
          "선택한 목록을 삭제하시겠습니까?"
        );
        if (!confirm.value) return;

        // [TODO] 설비유형 삭제 기능 확인 필요

        //checked.equipCnt 서버에서 결정해주지 않으므로 삭제후 오류 처리
        // if (checked.equipCnt === 0) {
        //     checked.forEach(async (item) => {
        //         await backEndApi.equipTypes.deleteEquipType(item.equipType);
        //     });
        //     await this.alertNoti("선택한 목록을 삭제하였습니다.")
        //     let clear = await this.getDataList();
        //     if(clear) await this.$refs['EquipTypeDetail'].clearData();
        //     else this.equipTypeList = null;
        // } else {
        //     this.alertNoti("설비에서 해당 유형을 참조하고 있어 삭제할 수 없습니다.");
        // }

        checked.forEach(async (item) => {
          try {
            await backEndApi.equipTypes.deleteEquipType(item.equipType);
          } catch (e) {
            console.error("Fail to delete EquipType", item);
            await this.alertNoti(
              "설비유형코드를 참조하고 있는 다른 객체가 있는 경우 삭제할 수 없습니다."
            );
          }
        });
        await this.alertNoti("선택한 목록을 삭제하였습니다.");

        let clear = await this.getDataList();
        if (clear) await this.$refs["EquipTypeDetail"].clearData();
        else this.equipTypeList = null;
      } catch (e) {
        this.alertNoti("삭제에 실패하였습니다.");
        console.error(e);
      }
    },

    async changeStatusChecked(options) {
      const that = this;

      let data = options;

      try {
        const checked = this.$refs["table-list"].getCheckedRows();
        if (!that.isEmpty(checked)) {
          checked.forEach(async (item) => {
            if (!that.isEmpty(data)) {
              data["equipType"] = item.equipType;

              if (data.hasOwnProperty("enabled")) {
                await backEndApi.equipTypes.setEquipTypeEnabled(data);
              } else {
                console.warn("Unknown Status Name");
              }
            }
          });
          await this.alertNoti("변경하였습니다.");
          await that.getDataList();
        } else {
          this.alertNoti("목록을 선택해주세요.");
        }
      } catch (e) {
        console.error(e);
        this.alertNoti("상태 변경에 실패하였습니다.");
      }
    },

    async changeStatus(key, field, status) {
      const data = {};
      data["equipType"] = key;
      data[field] = status;

      try {
        let result = await backEndApi.equipTypes.setEquipTypeEnabled(data);
        if (result.data) {
          await this.alertNoti("변경하였습니다.");
          await this.getDataList();
        }
      } catch (e) {
        console.error(e);
        this.alertNoti("상태 변경에 실패하였습니다.");
      }
    },

    async registNew() {
      // this.$refs['EquipTypeDetail'].newData();
      this.$refs.EquipTypeDetail.newData();
    },

    // async handleSaveData(data) {
    //     if (this.isEmpty(data.equipType)) {
    //         this.alertNoti("설비유형코드는 필수 입력 항목입니다.");
    //         return;
    //     }
    //     if (this.isEmpty(data.equipTypeName)) {
    //         this.alertNoti("설비유형명은 필수 입력 항목입니다.");
    //         return;
    //     }

    //     try {
    //         let result = await backEndApi.equipTypes.saveEquipType(data);
    //         if (result.data) {
    //             await this.alertNoti("저장하였습니다.");
    //             await this.getDataList();
    //             this.$refs['EquipTypeDetail'].clearData();
    //             // this.$refs['EquipTypeDetail'].setData(data);
    //         }
    //     } catch (e) {
    //         this.alertNoti("저장에 실패하였습니다.");
    //         console.error(e);
    //     }
    // },

    // Event Handling
    async handleDetailUpdated(type, data) {
      console.log(type);
      console.log(data);
      // reload
      await this.getDataList();
    },

    handleDetailClosed() {
      this.commonCode = null;
    },

    initCategoryItem() {
      console.warn("initCategoryItem() should be working!!!");
    },
  },
};
</script>

<style scoped>
.ld-container {
  height: 90vh;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: nowrap; */
  /* flex: 1; */

  height: 100%;
  margin-right: 0px;
  padding: 0px 10px 0px 10px;

  border-radius: 10px;
  border-top: 1px solid #f4f4f4;
  border-right: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
  border-left: 1px solid #f4f4f4;
  background-color: #f9f9f9;
}
</style>